import { Formik } from '../index';
import styled from 'styled-components';

const { Input } = Formik;

const BorderlessInput = styled(Input)`
  font-size: 24px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  &&& {
    input {
      font-size: 18px;
      background-color: #e6e6e6;
      color: #676767;
      padding-left: 0.5em;
    }
    input::placeholder {
      color: initial;
      opacity: 0.65;
    }
  }
  .ant-input-prefix {
    margin-right: 0.5em;
  }
`;

export default BorderlessInput;
