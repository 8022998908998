import { Formik } from 'formik';
import { Form as FormikForm } from 'formik-antd';
import _castArray from 'lodash/castArray';
import _isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '../Ant';

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const Form = ({ children, initialValues, handleSubmit, style, disableGlobalErrors, ...rest }) => {
  const processHandleSubmit = (values, actions) => {
    const errorHandler = reducerErrorTransformer => {
      const formErrorHandler = originalErrors => {
        console.log('processing form error handling', originalErrors); // eslint-disable-line no-console

        const errors = _isArray(originalErrors) ? originalErrors[0] : originalErrors;
        if (errors) {
          for (const key in errors) {
            const GLOBAL_KEY = 'global';
            const isGlobalKey = ['global', 'detail'].includes(key);

            let errorMessage = isGlobalKey
              ? _castArray(errors[key]).join(',')
              : `${key} ${errors[key].join(',')}`;

            errorMessage = errorMessage.capitalize();
            actions.setFieldError(isGlobalKey ? GLOBAL_KEY : key, errorMessage);
          }
        }
        actions.setSubmitting(false);
      };

      return err => {
        const errors = reducerErrorTransformer(err);
        return formErrorHandler(errors);
      };
    };

    return handleSubmit(values, actions, errorHandler).finally(() => actions.setSubmitting(false));
  };

  return (
    <Formik initialValues={initialValues} onSubmit={processHandleSubmit} {...rest}>
      {formikProps => {
        return (
          <>
            {!disableGlobalErrors && formikProps.errors && formikProps.errors.global && (
              <Alert message={formikProps.errors.global} type="error" />
            )}
            <FormikForm style={style}>
              {typeof children === 'function' ? children(formikProps) : children}
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  disableGlobalErrors: PropTypes.bool,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  style: PropTypes.object,
};

export default Form;
